import React from "react";
import { Redirect } from "react-router-dom";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import Categories from "./Categories";
import { Classes } from "./Classes";
import { useGetMenuOption } from "./hooks";
import { Programs } from "./Programs";
import { Header } from "./Header";
import { CenterWrapper, Container } from "./styles";
import { FreeClasses } from "./FreeClasses";
import { Instructors } from "./Instructors";
import { BasicClasses } from "./BasicClasses";

const Tabs = {
  classes: Classes,
  "free-classes": FreeClasses,
  "basic-classes": BasicClasses,
  programs: Programs,
  categories: Categories,
  instructors: Instructors,
};

export default () => {
  const { selectedOption, isValidMenuOption } = useGetMenuOption();
  const { isFamilyModeOn } = useFamilyModeSetting();

  if (!isValidMenuOption) {
    return <Redirect to="/not-found" />;
  }

  const Body = Tabs[selectedOption];

  return (
    <>
      <CenterWrapper pb={3}>
        <Container pt={[4, 4, 4, "42px"]}>
          {!isFamilyModeOn && <Header />}
        </Container>
      </CenterWrapper>
      <Body />
    </>
  );
};
